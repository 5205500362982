import profile1 from '../assets/profile1.png'
import profile2 from '../assets/profile2.png'

export const TestimonialsData = [
    {    
        id:1,
        image : profile1,
        comment: " Letraset sheets containing Lorem Ipsum passages,",
        name: "John Alendon"
    },
    {   
        id:2,
        image : profile2,
        comment: " Letraset sheets containing Lorem Ipsum passages, ",
        name: "Natasha"
    },
    {   
        id:3,
        image : profile1,
        comment: " Letraset sheets containing Lorem Ipsum passages,",
        name: "Alex Hormonzi"
    },
    {   
        id:4,
        image : profile1,
        comment: " Letraset sheets containing Lorem Ipsum passages, ",
        name: "John Alendon"
    },
    {   
        id:5,
        image : profile2,
        comment: " Letraset sheets containing Lorem Ipsum passages,",
        name: "Natasha"
    },
    {   
        id:6,
        image : profile1,
        comment: " Letraset sheets containing Lorem Ipsum passages,",
        name: "Alex Hormonzi"
    }
]